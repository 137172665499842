<template>
    <div class="pa-5">
        <utils-display-results-component/>
    </div>

</template>

<script>

import UtilsDisplayResultsComponent from './utils-display-result.vue';

export default {
    name: 'utils-main-component',
    components: {
        UtilsDisplayResultsComponent
    },
    data: function () {
        return {}
    },
    mounted() {

    },
    methods: {}
}
</script>
