<template>

    <div>


        <v-alert
            class="custom-alert"
            text
            variant="outlined">
            <p align="center" class="">🎁⭐🏆👉 WARNING: use these info at your own risk !</p>
        </v-alert>

        <v-card class="code-block">
            <v-card-title class="text-h6 color-dkt">
                <span>My DKT Bearer</span>
                <v-btn icon>
                    <v-icon @click="copyBearerDktToClipboard">mdi-content-copy</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="mb-10">
                <pre><code class="text-caption code-class"
                >{{ bearerDkt }}
                </code></pre>
            </v-card-text>
        </v-card>

        <v-card class="code-block">
            <v-card-title class="text-h6 color-dkt">
                <span>Android Management Api Bearer</span>
                <v-btn icon>
                    <v-icon @click="copyBearerAamToClipboard">mdi-content-copy</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="mb-10">
                <pre><code class="text-caption code-class"
                >{{ bearerAam }}
                </code></pre>
            </v-card-text>
        </v-card>

        <v-card class="code-block">
            <v-card-title class="text-h6 color-dkt">
                <span>Android Publisher Api Bearer</span>
                <v-btn icon>
                    <v-icon @click="copyBearerApToClipboard">mdi-content-copy</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="mb-10">
                <pre><code class="text-caption code-class"
                >{{ bearerAp }}
                </code></pre>
            </v-card-text>
        </v-card>

        <v-card class="code-block">
            <v-card-title class="text-h6 color-dkt">
                <span>MDM Vue.js properties</span>
                <v-btn icon>
                    <v-icon @click="copyEnvValuesToClipboard">mdi-content-copy</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="mb-10">
                <pre><code class="text-caption code-class"
                >{{ envValues }}
                </code></pre>
            </v-card-text>
        </v-card>

    </div>

</template>

<script>

import AuthenticationService from "@/service/auth/authentication-service";
import CacheApiService from "@/service/admin/cache/cache-api-service";

export default {
    name: "utils-display-result",
    props: {},
    data: function () {
        return {
            bearerDkt: null,
            bearerAam: null,
            bearerAp: null,
            envValues: null
        }
    },
    watch: {},
    async mounted() {
        this.loadDktBearer();
        this.loadEnvValues();
        this.loadAamBearer();
        this.loadApBearer();
    },
    methods: {
        loadDktBearer() {
            this.bearerDkt = "Bearer " + AuthenticationService.getSingleInstance().getAccessToken();
        },
        async getAndroidSomethingBearer(cacheKeyToSearch, defaultMessageToShow) {

            let valueToReturn = defaultMessageToShow;
            try {
                const cacheResult = (await CacheApiService.checkCacheKey(cacheKeyToSearch)).body.valuesListInCache.token;
                if (!cacheResult) {
                    throw new Error(defaultMessageToShow);
                }
                valueToReturn = "Bearer " + cacheResult;
            } catch (e) {
                console.error("loadAamBearer(" + cacheKeyToSearch + "): " + e.message);
            }
            return valueToReturn;

        },
        async loadAamBearer() {
            const aamBearerDefault = "NOT FOUND - Please generate an enrolment QR code to get one...";
            this.bearerAam = await this.getAndroidSomethingBearer("AAM_TOKEN_ANDROID_MANAGEMENT", aamBearerDefault);
        },
        async loadApBearer() {
            const aamBearerDefault = "NOT FOUND - Please generate policies recalculations to get one...";
            this.bearerAp = await this.getAndroidSomethingBearer("AAM_TOKEN_ANDROID_PUBLISHER", aamBearerDefault);
        },
        loadEnvValues() {
            const keyValuePairs = [];
            const obj = process.env;
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    keyValuePairs.push(`${key}=${value}`);
                }
            }

            this.envValues = keyValuePairs.join('\n');
        },
        copySomethingIntoClipBoard(callback) {
            try {
                console.log("copySomethingIntoClipBoard(): " + callback());
                navigator.clipboard.writeText(callback());
            } catch (e) {
                console.error("Could not copy the content into clipboard.", e);
            }
        },
        copyBearerDktToClipboard() {
            this.copySomethingIntoClipBoard(() => this.bearerDkt);
        },
        copyBearerAamToClipboard() {
            this.copySomethingIntoClipBoard(() => this.bearerAam);
        },
        copyBearerApToClipboard() {
            this.copySomethingIntoClipBoard(() => this.bearerAp);
        },
        copyEnvValuesToClipboard() {
            this.copySomethingIntoClipBoard(() => this.envValues);
        }

    }

};
</script>

<style>

.color-dkt {
    color: #3643ba;
}

.code-class {
    background-color: #f5f5f5 !important;
}

.code-block {
    background-color: #f5f5f5;
    font-family: Menlo, Monaco, 'Courier New', monospace;
    margin-top: 40px;
}

pre {
    white-space: pre-wrap;
    word-break: break-word;
    overflow: hidden;
    background-color: #f5f5f5;
    padding-top: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 0px !important;
    border-radius: 5px;
}

.custom-alert {
    background-color: #f8f5d9 !important;
    color: #927438 !important;
    border-radius: 5px !important;
    font-weight: bolder !important;
    border: 3px solid #927438 !important;
    padding-bottom: 0px;
    margin-left: 100px;
    margin-right: 100px;
}

</style>
