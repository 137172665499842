import Vue from "vue";
import {store} from "../../store/store";
import Moment from "moment";
import FieldConstant from "../../utils/field-constant";
import arboUtils from "../../utils/arbo-utils";
import httpRequestsUtils from "@/utils/http-requests-utils";
import objectUtils from "../../utils/object-utils";
import parameterApiService from "@/service/admin/parameter/parameter-api-service";
import AdminCacheApiService from "@/service/admin/cache/cache-api-service";
import newDeviceContants from "@/constant/new-device-constant";
import {DEVICE_CHOICE} from "./newDevice/step/step-constant";
import KioskInfoService from "@/service/devices/kiosk-info-service";

const camelcaseKeys = require('camelcase-keys');

export default {
    async requestScreenshot(kioskId) {
        await Vue.http.get(store.state.getUrlWithParams(store.state.urlRequestScreenshot, {kioskId}));
    },
    async hasToDisplayWsoEnrollmentTab() {
        let wsoEnrollmentFeatureFlipping = await parameterApiService.getJsonValueBy("front.wso.enrolment.perimeter.activated", newDeviceContants.WSO_ENROLMENT_PERIMETER_ACTIVATED);
        const wsoEnrollmentAuthorizedDeviceTypes = wsoEnrollmentFeatureFlipping.device_type.split(',');
        wsoEnrollmentFeatureFlipping = wsoEnrollmentFeatureFlipping.countries.split(',');
        const activeUserCountries = store.state.user?.countries || [];
        return wsoEnrollmentFeatureFlipping.some(country => activeUserCountries.includes(country) && wsoEnrollmentAuthorizedDeviceTypes.includes(DEVICE_CHOICE.data.deviceType));
    },
    async notifyAamKiosks(devicesToReceiveFirmwares) {
        const response = await Vue.http.post(store.state.urlNotifyAamKiosks, devicesToReceiveFirmwares);
        return response.body;
    },
    async checkIfIsKioskToReboot(key, value) {
        return AdminCacheApiService.checkCacheKeyAndValue(key, value);
    },
    async registerDevice(device, kiosk, storeSel) {
        let registerPayload = {...device};
        registerPayload.id = null;
        registerPayload.name = null;
        registerPayload.siteNumber = storeSel.id;
        registerPayload.entity = storeSel.type === FieldConstant.ENTITY.USER_INFO.STORE ? FieldConstant.ENTITY.RETAIL : FieldConstant.ENTITY.LOGISTICS;
        registerPayload.country = storeSel.country;
        registerPayload.androidManagementDeviceName = device.fullAndroidManagementDeviceName;
        registerPayload.apks = [];

        if (kiosk) {
            registerPayload.lockTime = {hour: kiosk.kioskStandByHour, minute: kiosk.kioskStandByMinute};
            registerPayload.rebootTime = {hour: kiosk.kioskRebootHour, minute: kiosk.kioskRebootMinute};
            registerPayload.defaultApp = kiosk.kioskDefaultApp;
            registerPayload.webviewName = kiosk.webview?.name;
            registerPayload.webviewUrl = kiosk.webview?.url;
        }

        return Vue.http.post(store.state.urlDeviceRegister, registerPayload).then((response) => {
            return response.body.data;
        });
    },
    async delete(id) {
        return Vue.http.delete(store.state.urlDeviceApiDeleteById.replace("${idDevice}", id))
            .then((response) => {
                return response.body.data;
            });
    },
    async isDeviceExist(idDevice) {
        try {
            const response = await Vue.http.get(store.state.getUrlWithParams(store.state.urlDeviceId, {idDevice}));
            return Boolean(response?.body?.id);
        } catch (error) {
            if (error.status !== 404) {
                console.error("isDeviceExist(): Api down for deviceId " + idDevice + ", " + JSON.stringify(error));
                throw error;
            }
        }
        return false;
    },
    async searchDevice(activeFilter, page, orderBy, arbo) {
        return this.searchDevicesDb(activeFilter, page, orderBy, arbo);
    },
    async searchMigrationDevices(activeFilters, pagination, orderBy, arbo, statusFilter) {
        const allFields = activeFilters.filter(field => field.type.startsWith(FieldConstant.DEVICE.SEARCH.CUSTOM)).map(field => field.value);
        const {page, pageSize} = pagination;

        const response = (await Vue.http.post(store.state.urlMigrationDeviceSearch, {
            filters: {
                allFields,
                perimeter: arboUtils.transformArboToRequirementForMigration(arbo)
            },
            pagination: {page: page - 1, pageSize},
            orderBy,
            dateLastCheck: Moment().subtract(3, 'months').format('YYYY-MM-DD'),
            statusFilter
        })).body;

        const filteredData = response.data.map(device => {
            let lastSynchroStatus;
            let lastSynchroStatusValue = Moment(device.date_last_check).diff(Moment(), 'days', true);
            if (lastSynchroStatusValue > -1) {
                lastSynchroStatus = FieldConstant.DEVICE.SYNCHRO_STATUS.SYNC_OK;
            } else if (lastSynchroStatusValue < -1 && lastSynchroStatusValue > -7) {
                lastSynchroStatus = FieldConstant.DEVICE.SYNCHRO_STATUS.SYNC_WARN;
            } else if (lastSynchroStatusValue < -7) {
                lastSynchroStatus = FieldConstant.DEVICE.SYNCHRO_STATUS.SYNC_KO;
            }

            device.date_last_check = {
                value: Moment(device.date_last_check).format(FieldConstant.DATE.FORMAT.DDMMYY),
                status: lastSynchroStatus
            };

            device = objectUtils.convertKeysToCamelCase(device);

            return device;
        });
        const devicesTotal = response.pagination.rowCount;
        const nbOfDevice = response.data.length;

        return {
            filteredData,
            devicesTotal,
            nbOfDevice
        };
    },
    async countDevices(searchTerms, arbo, devicesCountStats, isNewGlobalSearch) {

        const payload = this.getSearchDevicesDbPayload(searchTerms, arbo);
        const response = await Vue.http.post(`${store.state.urlCountStatDevices}`, payload);
        const camelResponse = camelcaseKeys(response.body, {deep: true});

        if (isNewGlobalSearch) return camelResponse;
        return devicesCountStats ? {...camelResponse, ...devicesCountStats} : camelResponse;
    },

    getSearchDevicesDbPayload(searchTerms, arbo, page = null, csvMode = false, orderBy = null) {
        let all_fields = [];
        let fields = [];

        const pageSize = csvMode === true ? FieldConstant.DEVICE.SEARCH.CSV_PAGE_SIZE : FieldConstant.DEVICE.SEARCH.PAGE_SIZE;

        let pagination = {
            page: page || 1,
            pageSize
        };

        let appAsked = false;
        for (let field of searchTerms) {
            if (field.type.startsWith(FieldConstant.DEVICE.SEARCH.CUSTOM)) {
                all_fields.push(field.value);
            } else {
                const valueToPush = {};
                valueToPush[field.type] = field.value;
                if (field.type === 'application') {
                    appAsked = true;
                }
                fields.push(valueToPush);
            }
        }
        let payload = {
            csv: csvMode,
            filters: {
                all_fields, fields,
                deep_search: appAsked,
                perimeter: arboUtils.transformArboToRequirement(arbo)
            }, pagination
        };
        orderBy && (payload.order_by = orderBy);
        return payload;
    },

    async callSearchDevicesDbApi(searchTerms, page, csvMode, orderBy, arbo, statusFilter) {

        const searchMode = csvMode ? "Export" : "Search";
        const defaultDelay = csvMode ? 5000 : 1000;
        const defaultRetryCount = csvMode ? 5 : 3;

        const payload = this.getSearchDevicesDbPayload(searchTerms, arbo, page, csvMode, orderBy, statusFilter);

        let requestFunc;
        if (store.state.migration.isMigrationExport) {
            const payloadMigrationExport = this.getExportMigrationDbPayload(payload, orderBy, statusFilter);
            requestFunc = async () => Vue.http.post(store.state["urlMigrationDevicesExport"], payloadMigrationExport);
        } else {
            requestFunc = async () => Vue.http.post(store.state[`urlDevicesApi${searchMode}`], payload);
        }

        const retryConfig = JSON.parse(process.env.VUE_APP_HTTP_REQUEST_RETRY_CONFIG || `{"count":${defaultRetryCount},"delay":${defaultDelay}}`);
        return httpRequestsUtils.retryRequestWhenFailed(requestFunc, retryConfig);
    },

    getExportMigrationDbPayload(payload, orderBy, statusFilter) {
        return {
            csv: payload.csv,
            filters: {
                allFields: payload.filters.perimeter.all_fields,
                perimeter: {
                    allRetail: payload.filters.perimeter.allRetail,
                    countries: payload.filters.perimeter.countries,
                    stores: payload.filters.perimeter.stores
                }
            },
            pagination: payload.pagination,
            orderBy,
            dateLastCheck: Moment().subtract(3, 'months').format('YYYY-MM-DD'),
            statusFilter
        };
    },

    async getOsVersions(os) {
        const result = await callGetOsVersions(os);
        return result.body;
    },

    async searchDevicesDb(searchTerms, page, orderBy, arbo) {
        const result = await this.callSearchDevicesDbApi(searchTerms, page, undefined, orderBy, arbo);

        let devices = [];
        result.body.data.forEach(function (el) {
            let lastSynchroStatus;
            let lastSynchroStatusValue = Moment(el.date_last_check).diff(Moment(), 'days', true);
            if (lastSynchroStatusValue > -1) {
                lastSynchroStatus = FieldConstant.DEVICE.SYNCHRO_STATUS.SYNC_OK;
            } else if (lastSynchroStatusValue < -1 && lastSynchroStatusValue > -7) {
                lastSynchroStatus = FieldConstant.DEVICE.SYNCHRO_STATUS.SYNC_WARN;
            } else if (lastSynchroStatusValue < -7) {
                lastSynchroStatus = FieldConstant.DEVICE.SYNCHRO_STATUS.SYNC_KO;
            }

            devices.push({
                id: el.id,
                name: el.name,
                entity: el.entity,
                siteId: el.site_id,
                country: el.country,
                countryCode: el.country?.toLowerCase(),
                category: el.device_type,
                manufacturer: el.manufacturer,
                mac: el.mac,
                model: el.model,
                agent: el.agent,
                os: el.os,
                osVersion: el.os_version,
                buildVersion: el.build_version,
                phoneNumber: el.phone_number,
                serialNumber: el.serial_number,
                simNumber: el.sim_number,
                user: el.connected_user,
                imeiValue: el.imei_value,
                ip: el.ip,
                dateEnrolment: Moment(el.date_enrolment).format(FieldConstant.DATE.FORMAT.DDMMYY),
                dateLastCheck: {
                    value: Moment(el.date_last_check).format(FieldConstant.DATE.FORMAT.DDMMYY),
                    status: lastSynchroStatus
                },
                displayPerso: el.enterprise ? "Corp" : "Perso",
                blocked: el.blocked,
                androidSilentUpdate: el.android_silent_update,
                androidManagementMode: el.android_management_mode,
                siteType: el.site_type,
                wifi: el.wlan,
                enrolledBy: el.enrolled_by,
                application: el.application,
                version: el.version
            });
        });

        return {
            devicesTotal: result.body.pagination.rowCount,
            nbOfDevice: result.body.data.length,
            devices: devices
        }
    },

    getKiosk(idDevice) {
        return Vue.http.get(store.state.getUrlWithParams(store.state.urlInfosKiosk, {idDevice})).then((response) => {
            let kiosk = response.body;
            return {
                kioskSite: kiosk.device_site,
                kioskName: kiosk.device_name,
                kioskPlace: kiosk.device_place,
                kioskDefaultApp: kiosk.device_app_default,
                kioskRebootHour: kiosk.device_reboot_hour,
                kioskRebootMinute: kiosk.device_reboot_minute,
                kioskStandByHour: kiosk.device_standby_hour,
                kioskStandByMinute: kiosk.device_standby_minute,
                kioskStartAt: getReadableHour(kiosk.device_reboot_hour || "0", kiosk.device_reboot_minute || "0"),
                kioskStopAt: getReadableHour(kiosk.device_standby_hour || "0", kiosk.device_standby_minute || "0"),
                idleModeInfo: {
                    mode: kiosk.idle_mode,
                    message: kiosk.idle_message,
                    time: kiosk.idle_mode_time
                },
                webview: {
                    name: kiosk.webview?.name, packageName: kiosk.webview?.package_name, url: kiosk.webview?.url
                },
                devMode: kiosk.dev_mode,
                screenOrientation: kiosk.screen_orientation
            }
        });
    },
    async updateOverriddenPolicyType(idDevice, overriddenPolicyType, deviceType) {
        return await Vue.http.patch(store.state.getUrlWithParams(store.state.urlDeviceIdUpdateOverriddenPolicy, {idDevice}), JSON.stringify({
            overriddenPolicyType,
            deviceType
        }))
    },
    async forceUpdateDevice(idDevice, device) {
        return await Vue.http.post(
            store.state.getUrlWithParams(`${store.state.urlForceUpdateApplicationsPolicies}`, {idDevice}), {device});
    },
    async updateDevice(idDevice, blocked) {
        return await Vue.http.patch(store.state.getUrlWithParams(store.state.urlDeviceUpdate, {idDevice}), JSON.stringify({blocked}))
    },
    formatAndroidDeviceName(androidManagementDeviceName) {
        return androidManagementDeviceName ? androidManagementDeviceName.split("/")[3] : "";
    },
    async disableKiosksDevModeBeforeFirmwareUpdate(kioskIdsToApplyFirmwareOnDevMode) {
        for (const kioskId of kioskIdsToApplyFirmwareOnDevMode) {
            try {
                await KioskInfoService.updateKioskInfo({device_id: kioskId, dev_mode: false})
                console.info(`Succeed to disable dev mode for kiosk: ${kioskId}`);
            } catch (e) {
                console.error(`Failed to disable dev mode for kiosk: ${kioskId}`);
            }
        }
    }
};

const getReadableHour = (kioskHour, kioskMinute) => {
    let readableHour = "";

    if (kioskHour.length === 1) readableHour += "0";
    readableHour += kioskHour + ":";

    if (kioskMinute.length === 1) readableHour += "0";
    return readableHour + kioskMinute + ":00";
}

const callGetOsVersions = (os) => {
    let url = store.state.getUrlWithParams(
        store.state.urlDevicesOsVersions,
        {os}
    );
    return Vue.http.get(url, {});
}


