export default {
    WAITING_TO_WIPE_FROM_MDM: "Waiting to wipe",
    WIPED_FROM_MDM: "Wiped",
    ENROLLED_ON_WSO: "Enrolled",
    NOT_ENROLLED_ON_WSO: "Not enrolled",
    TO_MIGRATE_TO_WSO: "To migrate",
    MIGRATED_TO_WSO: "Migrated",
    MDM_DETAIL_URL_TXT: "See the device on MDM",
    WSO_DETAIL_URL_TXT: "See the device on WSO",
    NOT_ON_MDM_WSO: "This device has been wiped from MDM but it is not enrolled on WSO",
    WSO_URL: "https://decathlon.awmdm.com/AirWatch/#/AirWatch/Search?query=",
    STATUS: {
        MDM_STATUS: "mdmStatus",
        WSO_STATUS: "wsoStatus",
        MIGRATION_STATUS: "migrationStatus"
    }
};
