<template>
    <v-data-table id="devices-search-table" v-if="devices"
                  :headers="headersToShow"
                  :items="devices"
                  :items-per-page="pageSize"
                  hide-default-footer
                  hide-default-header>

        <template v-slot:header="{ props }">
            <th class="text-start" v-for="head in props.headers">
                <div class="header-wrapper">
                    <span :class="getHeaderClass(head)"> {{ head.text }} </span>
                    <div v-if="isAllowedHeader(head.col)" class="sort-icons-wrapper ml-2 mt-n1">
                        <i class="ri-arrow-up-s-line cursor-hover" :class="getSortClass(head, 'asc')" @click="sortBy(head.col, 'asc')"></i>
                        <i class="ri-arrow-down-s-line cursor-hover" :class="getSortClass(head, 'desc')" @click="sortBy(head.col, 'desc')"></i>
                    </div>
                </div>
            </th>
        </template>

        <template v-slot:item.serialNumber="{ item, index}">
            <div class="font-weight-bold h3">
                <mdm-vtmn-popover class="max-content" :position="getPopoverPosition(index)"  :id="'up-to-date-popover-'" :maxWidth=400>
                   <div class="serial-numb">
                    <p :class="displayDetailContainer(item) ? 'black-serial' : 'grey-serial'">{{ item.serialNumber }}</p>
                    <v-icon small id="group-eye-icon" class="ml-2 text-error">
                        mdi-eye-outline
                    </v-icon>
                   </div>   
                   <template v-slot:content>
                    <div class="urls" v-if="displayDetailContainer(item)">
                        <div class="url" v-if="item.mdmStatus === migrationConstants.WAITING_TO_WIPE_FROM_MDM">
                            <a :href="getDeviceLink(item, false)" target="_blank">{{migrationConstants.MDM_DETAIL_URL_TXT}}</a>
                            </div>
                        <div v-if="item.wsoStatus === migrationConstants.ENROLLED_ON_WSO" class="url">
                            <a :href="getDeviceLink(item, true)" target="_blank">{{migrationConstants.WSO_DETAIL_URL_TXT}}</a>
                        </div>
                    </div>
                    <div v-else class="non-on-mdm-wso">
                        {{migrationConstants.NOT_ON_MDM_WSO}}
                    </div>
                   </template>
                   
                </mdm-vtmn-popover>
            </div>
        </template>

        <template v-slot:item.dateLastCheck.value="{ item }">
            <div class="date-container">{{ item.dateLastCheck.value }}
                <span class="dot" :class="'dot-' + item.dateLastCheck.status"></span>
            </div>
        </template>

        <template v-slot:item.mdmStatus="{ item }">
            <div class="status-column">
                <hourglass v-if="item.mdmStatus === migrationConstants.WAITING_TO_WIPE_FROM_MDM"/>
                <bin-mdm-status v-else />
            </div>
        </template>

        <template v-slot:item.wsoStatus="{ item }">
            <div class="status-column">
                <check-circle v-if="item.wsoStatus === migrationConstants.ENROLLED_ON_WSO"/>
                <close-circle v-else />
            </div>
        </template>

        <template v-slot:item.isProDevice="{ item }">
            <div>
                <span v-if="item.isProDevice">Corporate</span>
                <span v-else>Personal</span>
            </div>
        </template>

        <template v-slot:item.wipeDate="{ item }">
            <div>
                <span v-if="item.wipeDate">{{ formatWipeDate(item.wipeDate) }}</span>
                <span v-else>-</span>
            </div>
        </template>

    </v-data-table>
</template>

<script>
    import DeviceSearchConstants from "../../../../utils/device-search-constant";
    import FieldConstants from "../../../../utils/field-constant";
    import migrationConstants from "../../../../constant/migration-constants";
    import Hourglass from "../../icons/hourglass-icon.vue";
    import BinMdmStatus from "../../icons/bin-mdm-status-icon.vue";
    import CloseCircle from "../../icons/close-circle-icon.vue";
    import CheckCircle from "../../icons/check-circle-icon.vue";
    import Moment from "moment/moment";
    import FieldConstant from "../../../../utils/field-constant";

    export default {
        name: "device-search-table",
        components: {CheckCircle, CloseCircle, Hourglass, BinMdmStatus},
        props: {
            headers: {type: Array, required: false}, // cannot use this.$vuetify.breakpoint to use the default option here
            devices: {type: Array, required: true},
            pageSize: {type: Number, required: false, default: () => FieldConstants.DEVICE.SEARCH.PAGE_SIZE},
            orderBy: {type: Object, required: false}
        },
        data: function () {
            return {
                headersToShow: this.getHeaders(),
                latestSortValue: null,
                migrationConstants: migrationConstants,
            };
        },
        watch: {
            orderBy: function (newVal, oldVal) {
                if (!(!this.latestSortValue || (newVal?.key === this.latestSortValue?.key && newVal?.order === this.latestSortValue?.order))) {
                    this.sortBy(newVal?.key, newVal?.order);
                }
            },
            headers: function () {
                this.headersToShow = this.headers
            }

        },
        methods: {
            isAllowedHeader(header) {
                const {STATUS: {MDM_STATUS, WSO_STATUS, MIGRATION_STATUS}} = migrationConstants;
                return ![MDM_STATUS, WSO_STATUS, MIGRATION_STATUS].includes(header);
            },
            getDeviceLink(item, isForWso) {
                if(isForWso){
                    return migrationConstants.WSO_URL + item.serialNumber;
                }
                return this.$router.resolve({ name: 'device-show-one', params: { id: item.id } }).href;
            },
            getPopoverPosition(index){
                return index > 1 ? "top-right" : "bottom-right";
            },
            displayDetailContainer(item){
                return item.wsoStatus === migrationConstants.ENROLLED_ON_WSO || item.mdmStatus === migrationConstants.WAITING_TO_WIPE_FROM_MDM
            },
            getHeaders(){
                return this.$vuetify.breakpoint.mdAndUp ? this.headers : DeviceSearchConstants.DEFAULT_MOBILE_MIGRATION_HEADERS
            },
            getSortClass(header, sort) {
                return header?.sort && header.sort?.order === sort ? "full-sort-icon" : "";
            },
            getHeaderClass(header) {
                return header?.sort ? "full-sort-icon" : null;
            },
            sortBy(col, sortDirection) {
                const sort = {key: col, order: sortDirection};
                let cancelled = false;
                this.headersToShow = this.headersToShow.map(h => {
                    if (h.sort?.key === col && h.sort?.order === sortDirection) {
                        cancelled = true;
                    }
                    return {...h, sort: col === h.col ? cancelled ? null : sort : null};
                });
                this.latestSortValue = sort;
                this.$emit("startSearch", cancelled ? null : sort);
            },
            formatWipeDate(wipeDate) {
                return Moment(wipeDate).format(FieldConstant.DATE.FORMAT.DDMMYY);
            }
        }
    };
</script>

<style lang="scss">
.urls {
    display: flex;
    flex-direction: column;
}
.url{
    width: 200px;
    height: 30px;
    border-radius: 5;
    border-radius: 10px;
    display: flex;
    border: 2px solid white; 
    padding: 10px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
}
    .serial-numb {
        display: flex;
        align-items: baseline;
        margin-top: 20px;
    }
    .grey-serial {
        color: rgb(168, 168, 193);
        font-weight: bold;
    }
    .black-serial {
        color: rgb(3, 3, 19);
        font-weight: bold;
    }
    .header-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        @media only screen and (max-width: 600px) and (min-width: 0px) {
            flex-direction: column;

        }
    }

    .sort-icons-wrapper {
        display: flex;
        flex-direction: column;
        text-align-last: right;
        @media only screen and (max-width: 600px) and (min-width: 0px) {
            align-items: center;
            padding-top: 5px;
        }
    }

    .date-container {
        display: flex;
    }

    #devices-search-table {
        div {
            @media only screen and (max-width: 600px) and (min-width: 0px) {
                text-align: center;
            }
        }

        td {
            vertical-align: middle;
            height: 48px;
            @media only screen and (max-width: 600px) and (min-width: 0px) {
                display: table-cell;
                font-size: 0.6em;
                padding-left: 0;
                padding-right: 0;
            }
        }

        th {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            text-align-last: left;
            padding-left: 16px;
            padding-bottom: 10px;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: var(--vtmn-color_grey-light-2);
            @media only screen and (max-width: 600px) and (min-width: 0px) {
                text-align-last: center;
            }
        }

        tr {
            &:nth-of-type(even) {
                background-color: var(--vtmn-color_grey-light-4) !important;
                border: hidden !important;
            }

            &:hover {
                cursor: pointer;
            }

            @media only screen and (max-width: 600px) and (min-width: 0px) {
                display: table-row;
            }
        }

        table {
            min-width: 80%;
            @media only screen and (max-width: 600px) and (min-width: 0px) {
                min-width: unset;
                width: 100%;
                table-layout: fixed;
                overflow-wrap: break-word;
            }
        }
    }

    .dot {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: bottom;
        margin-left: 3px;
    }

    .dot-SYNC_OK {
        background-color: var(--vtmn-color_success);
        font-size: 40px;
    }

    .dot-SYNC_KO {
        background-color: var(--vtmn-color_danger);
    }

    .dot-SYNC_WARN {
        background-color: var(--vtmn-color_orange-light-1);
    }

    .truncate-serial-number {
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cursor-hover:hover {
        cursor: pointer;
    }

    .full-sort-icon {
        font-size: 13px;
        font-weight: bolder;
    }

    .v-data-table--mobile>.v-data-table__wrapper tbody {
        display: contents;
    }

    .default-row-color{
        color: var(--vtmn-color_black) !important;
    }
    .grey-row-color{
        color: var(--vtmn-color_grey) !important;
    }
    .opacity-05{
        opacity:0.5;
    }

    .status-column {
        display: flex;
        justify-content: center;
    }

</style>
