import Vue from "vue";
import {store} from "../../../store/store";

export default {
    async checkCacheKeyAndValue(key, value) {
        return await Vue.http.get((store.state.getUrlWithParams(store.state.urlCacheCheck, {key, value})));
    },
    async checkCacheKey(key) {
        return await Vue.http.get((store.state.getUrlWithParams(store.state.urlCacheKey, {key})));
    }
}
