import CacheMainComponent from '../pages/admin/cache/cache-main';
import UtilsMainComponent from '../pages/admin/utils/utils-main.vue';
import HeaderConfiguration from '../pages/admin/header/header-configuration';
import FirmwareMain from "@/pages/admin/firmware/firmware-main";

const CRITICITY_LEVEL = {
    MINOR: 'MINOR',
    MAJOR: 'MAJOR',
    RESOLVED: 'RESOLVED'
}

export default {
    ERROR: {
        HEADER_CONFIGURATION_SAVING_ERROR: "An error occurred when you try get the header configuration",
        HEADER_CONFIGURATION_GETTING_ERROR: "An error occurred when you try save the header configuration",
        FIRMWARE_FETCH_DATA_ERROR: "An error occurred while fetching firmware data"
    },
    WARN: {
        NO_FIRMWARE_FOUND: "No firmwares found"
    },
    CACHE: {
        NAME: 'Cache',
        RENDER: CacheMainComponent
    },
    HEADER: {
        ...CRITICITY_LEVEL,
        NAME: 'Header',
        RENDER: HeaderConfiguration,
        CRITICITY_LEVEL: [
            {NAME: CRITICITY_LEVEL.MINOR, canClose: true, icon: 'ri-alert-line'},
            {NAME: CRITICITY_LEVEL.MAJOR, canClose: false, icon: 'ri-alarm-warning-line'},
            {NAME: CRITICITY_LEVEL.RESOLVED, canClose: true, icon: 'ri-checkbox-circle-line'}
        ]
    },
    FIRMWARE: {
        NAME: "Firmware",
        RENDER: FirmwareMain
    },
    UTILS: {
        NAME: '🎁⭐🏆👉',
        RENDER: UtilsMainComponent
    },
}
