<template>
	<footer class='footer'>
		<md-progress-spinner v-if='loading' class='loading' :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>

		<v-row align="center" no-gutters class="footer-link-layout" >
            <v-col>
                <a href="https://sites.google.com/decathlon.com/decathlon-devices/home" target="_blank" class="footer-item">Google site - Devices</a>
            </v-col>
            <v-col>
				<a href="https://sites.google.com/decathlon.com/decathlon-devices/home" target="_blank" class="footer-item">Google site - Mobiles</a>
			</v-col>
            <v-col>
				<a href="https://docs.google.com/presentation/d/1yDm5iqasdJO1H99Ws6ivIXEStDb3E7bE" target="_blank" class="footer-item">Decathlon Services V2</a>
			</v-col>
            <v-col>
				<a @click="showRatingsPopup" class="footer-item">Feedback</a>
			</v-col>
            <v-col>
                <a @click="getApiVersions" class="footer-item">About</a>
            </v-col>
			<img src="./logoBureau.png" class="footer-logo"/>
		</v-row>

		<div v-if="popin != null" class="popins">
			<div class='popin-overlay' @click='popin = null'></div>
			<div v-if="popin =='showRate'" class="popin popin-delete">
				<img src="~@/components/Header/logoMDM.png" class="logoMDM center"/>
				<div class='popin-header'>REVIEWS</div>
				<star-rating read-only v-bind:increment="0.01" v-bind:round-start-rating="false" v-bind:fixed-points="2" v-model="rating" v-bind:star-size="45"></star-rating>
				<div><md-icon class="icon_count">person</md-icon> {{ratingCount}} total</div>
				<vue-chart
					chart-type="google.charts.Bar"
					:columns="columns"
					:rows="rows"
					:options="options"
				></vue-chart>
				<div class='popin-delete-actions popin-actions'>
					<md-button class="md-raised" @click="popin = null">CLOSE</md-button>
				</div>
			</div>
		</div>
		<md-dialog-alert id="alertVersion"
		:md-active.sync="first"
		:md-content="versionMessage"
		md-confirm-text="OK" />
	</footer>
</template>

<script>

import Vue from 'vue';
import AuthenticationService from "../../service/auth/authentication-service";

export default {
		data : function(){
		return {
		  first: false,
      versionMessage: '',
			popin: null,
			loading : false,
			rating : 0,
			ratingCount :0,
			columns: [
				{
					'type': 'string',
				},
				{
					'type': 'number',
				}
			],
			rows : null,
		    options: {
			    legend: { position: "none" },
			    colors : ['#81D4FA'],
			    bars: 'horizontal',
		        height: 145,
		        width : 400,
			}
		}
	},
	methods: {
    getApiVersions : function(){
      return Vue.http.get(`${this.$store.state.urlBackendVersion}`).then((response) => {
        const pjson = require('../../../package.json');
        const version = pjson.version;
        const backVersion = response.body.data;
        this.versionMessage="Version of the front : "+ version + "</br>Version of the backend (legacy java) : "+ backVersion;
        this.first= true;
      }, (error) => {
        console.log(error);
      });
    },
    showRatingsPopup : function(){
        document.getElementById('feedback').click();
    }
	},
	computed: {
		ApplicationRate : function() {
      const packageName = "com.decathlon.mdm";
      const token = AuthenticationService.getSingleInstance().getAccessToken();
      let query = {
        "query": "query getRatings($package: String!, $Authorization: String!,$typeData: String!, $store: String,$country: String, $version: String, $maxHits: Int!) { datas(packageName: $package, Authorization: $Authorization,store: $store,country: $country, typeData:$typeData, version: $version, maxHits: $maxHits) {totalEnreg summaryApp{ doc_count rating } aggregationSimple{key doc_count} } }",
        "variables": {
          "maxHits": 3,
          "typeData": "country",
          "country": null,
          "store": null,
          "version": null,
          "package": packageName,
          "Authorization": 'Bearer ' + token
        }
      }
      return query
    }
	}
}
</script>


<style lang="scss">
@import './footer'

</style>

